@font-face {
    font-family: 'days';
    src: local('days'),
    url('/fonts/Days.ttf') format('truetype')
}

@font-face {
    font-family: 'NotoSans-Regular';
    src: local('NotoSans'),
    url('/fonts/NotoSans-Regular.ttf') format('truetype')
}

header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 50;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	transition: top 0.3s;
	background-color: #8bc34a;
    transition: all 0.3s ease;
}

header h1 {
	font-family: days;
}

header.dark-mode {
	background-color: #122925;
    transition: all 0.3s ease;
}

.header_body {
	z-index: 2;
	display: flex;
	justify-content: space-between;
	height: 60px;
	align-items: center;
}

.header_list {
	display: flex;
	position: relative;
	z-index: 2;
}

.header_list li {
	list-style: none;
	margin: 0px 0px 0px 20px;
}

.header_link {
	color: white;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 17px;
    transition: all 0.3s ease;
}

.header_link.dark {
	color: #345751;
    transition: all 0.3s ease;
}

.header_menu {
	padding: 15px;
}

.main {
    color: #0d47a1;
    text-decoration: none;
    padding-left: 15px;
    font-family: days;
    font-size: 30px;
}

.main_small {
	display: none;
	opacity: 0;
    font-family: days;
    font-size: 30px;
}

header.dark-mode .main {
    color: #345751;
    transition: all 0.3s ease;
}

.theme-switch {
	margin-left: 20px;
	position: relative;
	display: inline-block;
}

/* Стили для текстовой надписи в подсказке */
.theme-switch .tooltiptext {
	visibility: hidden;
  	width: 120px;
  	background-color: #555;
	opacity: 0;
  	color: #fff;
  	text-align: center;
  	border-radius: 6px;
  	padding: 5px;
  	position: absolute;
  	z-index: 1;
  	top: calc(100% + 5px); /* Расположение под кнопкой */
	left: calc(50% - 40px);
  	transform: translateX(-50%);
  	transition: opacity 0.3s ease, visibility 0.5s ease;
	transition-delay: 0.5s;
}

/* Показываем подсказку при наведении на объект */
.theme-switch:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

#theme-toggle-btn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

#mySvg {
	fill: #345751;
    transition: all 0.3s ease;
}

header.dark-mode #mySvg {
	fill: #8bc34a;
    transition: all 0.3s ease;
}

@media screen and (max-width: 953px) {
    .main_small {
        text-decoration: none;
        color: #0d47a1;
        display: block;
        padding-left: 20px;
        opacity: 1;
        transition: all 0.3s ease;
    }

    header.dark-mode .main_small {
        color: #345751;
        transition: all 0.3s ease;
    }

    .main {
        display: none;
        opacity: 0;
    }
}

@media (max-width: 798px) {
    .header_burger {
        display: block;
        position: relative;
        width: 30px;
        height: 20px;
        z-index: 3;
    }

    .header_burger:before,
    .header_burger:after,
    .header_burger span {
        content: '';
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 2px;
        left: -10px;
        transition: all 0.3s ease 0s;
    }

    .header_burger:before {
        top: 0;
        transform: translateY(0px);
    }

    .header_burger:after {
        bottom: 0;
        transform: translateY(0px);
    }

    .header_burger span {
        top: 50%;
        transform: translateY(-50%);
    }
    
    .header_burger.active:before {
        transform: rotate(45deg);
        top: 9px;
    }
    
    .header_burger.active:after {
        transform: rotate(-45deg);
        bottom: 9px;
    }
    
    .header_burger.active span {
        transform: scale(0);
    }
    
    .header_menu {
        position: fixed;
        top: -100%;
        left: 0;
        width: 100%;
        height: auto;
        padding-top: 20px;
        background-color: #8bc34a;
        padding: 55px 10px 20px 10px;
        transition: all 0.4s ease 0s;
        text-align: center;
    }
    
    .header_menu.dark {
        background-color: #0f221f;
        color: #345751;
    }
    
    .header_menu.active {
        top: 0;
        transition: all 0.4s ease 0s;
    }
    
    .header_body {
        height: 50px;
    }
    
    .header_list {
        display: block;
    }
    
    .header_link.dark {
        color: #345751;
    }
    
    .header_list li {
        margin: 0px 0px 20px 0px;
    }

    .main {
        padding-left: 10px;
        font-size: 25px;
        display: block;
        opacity: 1;
        z-index: 4;
        position: relative;
    }
    
    .main_small {
        display: none;
        opacity: 0;
    }

    .theme-switch .tooltiptext {
        left: 50%;
        transform: translateX(-50%);
    }
}