.error-message {
    width: 80%;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 2px solid #000000;
    border-radius: 5px;
    text-align: center;
    transition: all 0.3s ease;
    background-color: white;
    transition: all 0.3s ease;
}

.error-message.dark {
    background-color: #1d2e2b;
    transition: all 0.3s ease;
}

.error-message .home-button {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    background-color: #8bc34a;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.error-message .home-button:hover {
    background-color: #689f38;
    transition: all 0.3s ease;
}

.logo {
    color: #0d47a1;
    font-family: days;
}

.logo.dark {
    color: #345751;
}