p {
    text-align: center;
    font-size: 24px;
    padding-top: 20px;
}

.message {
    margin: 50px auto;
    padding: 20px;
    text-align: center;
}
.tg-channel {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    text-decoration: none;
    background-color: #8bc34a;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
.tg-channel:hover {
    background-color: #689f38;
}