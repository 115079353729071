@font-face {
    font-family: 'days';
    src: local('days'),
    url('/fonts/Days.ttf') format('truetype')
}

@font-face {
    font-family: 'NotoSans-Regular';
    src: local('NotoSans'),
    url('/fonts/NotoSans-Regular.ttf') format('truetype')
}

*, *:before, *:after {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}

.no-select {
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

main {
    width: 100%;
    max-width: 1000px; /* Максимальная ширина контейнера */
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Тень контейнера */
    flex-direction: column; /* Определяем направление столбцом */
    margin: 0 auto; /* Центрирование контейнера */
}

body {
    display: flex;
    flex-direction: column;
    margin: 0;
}

body::after {
    content: '';
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('/src/images/background.webp');
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(70px); 
    z-index: -1;
}

body.dark-mode::after {
    background-image: url('/src/images/background-dark.webp');
}

.content {
    flex: 1;
    padding-top: 80px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#footer-container {
    display: flex;
    padding: 20px;
    margin-top: auto; /* Чтобы футер прижался к низу */
}

#footer-container > div {
    display: flex;
}

.links, .add-links {
    margin-right: 20px;
}

.link-yt, .link-tg, .link-vk, .link-pwa, .link-test {
    display: block;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-decoration: none;
    transition: all 0.3s ease;
}

.link-yt {
    color: #bd4333;
}

.link-tg {
    color: #3485e0;
}

.link-vk {
    color: #0077FF;
}

.link-pwa {
    color: #0d47a1;
}

.link-test {
    color: #0d47a1;
}

.links {
    padding-left: 20px;
    font-family: days;
}

.links li {
    padding-top: 10px;
    list-style: none;
}

.add-links {
    padding-left: 20px;
    font-family: days;
}

.add-links li {
    padding-top: 10px;
    list-style: none;
}

.links.dark {
    color: #345751;
    transition: all 0.3s ease;
}

.links.dark .link-yt {
    color: #345751;
    transition: all 0.3s ease;
}

.links.dark .link-tg {
    color: #345751;
    transition: all 0.3s ease;
}

.links.dark .link-vk {
    color: #345751;
    transition: all 0.3s ease;
}

.add-links.dark {
    color: #345751;
    transition: all 0.3s ease;
}

.add-links.dark .link-pwa {
    color: #345751;
    transition: all 0.3s ease;
}

.add-links.dark .link-test {
    color: #345751;
    transition: all 0.3s ease;
}

.link-yt:hover {
    text-decoration: underline;
}

.link-tg:hover {
    text-decoration: underline;
}

.link-vk:hover {
    text-decoration: underline;
}

.link-pwa:hover {
    text-decoration: underline;
}

.link-test:hover {
    text-decoration: underline;
}

body.dark-mode {
    background-color: #0f221f;
}

footer {
    background-color: #8bc34a;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
}

footer.dark-mode {
    background-color: #122925;
    transition: all 0.3s ease;
}

.copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    background-color: #476e1b;
    width: 100%;
    height: 30px;
    font-family: sans-serif;
    transition: all 0.3s ease;
}

.copyright.dark {
    color: #a5b4b1;
    background-color: #0f221f;
    transition: all 0.3s ease;
}

p {
    margin-bottom: 20px;
}

html, body {
    height: 100%;
}

noscript {
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #8bc34a;
    position: absolute;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 1000;
}

@media screen and (max-width: 953px) { }

@media (max-width: 798px) {
    body.lock {
        overflow: hidden;
    }

    .content {
        padding-top: 65px;
    }

    #footer-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
    }

    #footer-container > div {
        flex-direction: column;
    }

    .links {
        text-align: center;
        padding-bottom: 10px;
    }

    .add-links {
        text-align: center;
    }
}

